@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';
// app global css in SCSS form
//@import "~tailwindcss/base";
@import "~tailwindcss/components";
@import "~tailwindcss/utilities";

h1, h2, h3, h4, h5, h6 {
  margin: 0
}

h1 {
  font-size: 2.125rem;
  font-weight: 400;
  line-height: 2.5rem;
  letter-spacing: 0.00735em;
}

h2 {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  letter-spacing: normal;
}

h3 {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em;
}

h4, h5, h6 {
  font-size: 1.12rem;
  font-weight: 500;
  line-height: 2rem;
  letter-spacing: 0.0125em;
}


hr {
  border: 0;
  border-top: 1px solid $grey-3;
}

.q-card {
  background-color: #FAFAFA;
}

.filled-white .q-field__control {
  background-color: white;
}
